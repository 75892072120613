////////////////////////////////////////////////////////////////////////////////////////////////////////
//= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
// Heading styles
//= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
////////////////////////////////////////////////////////////////////////////////////////////////////////
.mat-display-4 {
  font-size: 2.875rem;
  line-height: 3.375rem;
  font-weight: 700;
  font-family: "Fira Sans";
}
.mat-display-3 {
  font-size: 1.945rem;
  line-height: 1.975rem;
  font-weight: 400;
  font-family: "Fira Sans";
}
.mat-display-2 {
  font-size: 1.45rem;
  line-height: 1.475rem;
  font-weight: 400;
  font-family: "Fira Sans";
}
.mat-display-1 {
  font-size: 1.224rem;
  line-height: 1.362rem;
  font-weight: 400;
  font-family: "Fira Sans";
}
.mat-display-0 {
  font-size: 1.124rem;
  line-height: 1.24rem;
  font-weight: 400;
  font-family: "Fira Sans";
}
.mat-headline {
  font-size: 1.32rem;
  line-height: 1.5rem;
  font-weight: 300;
  font-family: "Fira Sans";
}
.mat-headline-2 {
  font-size: 1.18rem;
  line-height: 1.5rem;
  font-weight: 300;
  font-family: "Fira Sans";
}
.mat-headline-3 {
  font-size: 1rem;
  line-height: 1.4rem;
  font-weight: 300;
  font-family: "Fira Sans";
}
.mat-title {
  font-size: 1rem;
  line-height: 2rem;
  font-weight: 400;
  font-family: "Fira Sans";
}
.mat-subheading {
  font-size: 0.94rem;
  line-height: 1rem;
  font-weight: 300;
  font-family: "Fira Sans";
}
.mat-body-2 {
  font-size: 0.891rem;
  line-height: 1.5rem;
  font-weight: 300;
  font-family: "Fira Sans";
}
.mat-body-1 {
  font-size: 0.891rem;
  line-height: 1.25rem;
  font-weight: 300;
  font-family: "Fira Sans";
}
.mat-button {
  font-size: 1.04rem;
  line-height: 1rem;
  font-weight: 400;
  font-family: "Fira Sans";
}
.mat-caption {
  font-size: 0.75rem;
  line-height: 1.25rem;
  font-weight: 300;
  font-family: "Fira Sans";
}
