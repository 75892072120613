////////////////////////////////////////////////////////////////////////////////////////////////////////
//= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
// Components styles
//= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
////////////////////////////////////////////////////////////////////////////////////////////////////////

//BUTTONS
.contained-button,
.outlined-button {
  cursor: pointer;
  display: grid;
  place-items: center;
  border-radius: 50px;
  padding: 0.5rem 1rem;
  font-size: 1.04rem;
  line-height: 1rem;
  font-weight: 400;
  font-family: "Fira Sans";
}
.contained-button {
  color: white;
  background-color: var(--primary-color);
  border: none;
  outline: none;
  white-space: nowrap;
  position: relative;
  overflow: hidden;
  &::after {
    content: " ";
    background: linear-gradient(
      90deg,
      rgba(0, 0, 0, 0.14) 0%,
      rgba(0, 0, 0, 0.06) 10%,
      rgba(0, 0, 0, 0.01) 50%,
      rgba(0, 0, 0, 0.06) 90%,
      rgba(0, 0, 0, 0.14) 100%
    );
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    max-width: 0px;
    width: 100%;
    height: 100%;
    z-index: 1;
    will-change: max-width;
    transition: max-width 0.34s ease-in;
  }
  &:hover::after {
    max-width: 300px;
  }
  &:hover {
    box-shadow: 1px 2px 8px rgba(0, 0, 0, 0.24);
    &:disabled {
      box-shadow: unset;
    }
  }
  &:disabled {
    &::after {
      content: unset;
    }
    background-color: var(--primary-lighter-color);
    &[data-color="accent"] {
      background-color: var(--accent-lighter-color);
    }
    &[data-color="warn"] {
      background-color: var(--warn-lighter-color);
    }
  }
  &[data-color="accent"] {
    background-color: var(--accent-color);
  }
  &[data-color="warn"] {
    background-color: var(--warn-color);
  }
  &[data-color="basic"] {
    color: var(--text-primary-color);
    background-color: inherit;
  }
  &[data-color="basic"]:hover {
    box-shadow: unset;
  }
  &[data-color="none"] {
    background-color: var(--text-primary-color);
  }
  & > * {
    z-index: 2;
  }
}
.outlined-button {
  color: var(--primary-color);
  background-color: transparent;
  border: 2px solid var(--primary-color);
  outline: none;
  white-space: nowrap;
  border: 2px solid currentColor;
  &:hover {
    box-shadow: 1px 2px 8px rgba(0, 0, 0, 0.24);
    background-color: var(--primary-200-color);
    &:disabled {
      box-shadow: unset;
    }
  }
  &:disabled {
    background-color: var(--primary-lighter-color);
    &[data-color="accent"] {
      background-color: var(--accent-lighter-color);
    }
    &[data-color="warn"] {
      background-color: var(--warn-lighter-color);
    }
    &[data-color="none"] {
      background-color: var(--border-color);
    }
  }
  &[data-color="accent"] {
    color: var(--accent-color);
  }
  &[data-color="accent"]:hover {
    background-color: var(--accent-200-color);
  }
  &[data-color="warn"] {
    background-color: var(--warn-color);
  }
  &[data-color="warn"]:hover {
    background-color: var(--warn-200-color);
  }
  &[data-color="none"] {
    color: currentColor;
  }
  &[data-color="none"]:hover {
    background-color: #3c404328;
  }
}
.flat-button {
  --icon-size: 3rem;
  border-radius: 50%;
  width: var(--icon-size);
  height: var(--icon-size);
  font-size: 2.15em;
  box-shadow: 1px 2px 8px rgba(0, 0, 0, 0.24);
  background-color: var(--primary-color);
  color: #fff;
  border: none;
  outline: none;
  display: grid;
  place-items: center;
  cursor: pointer;
  &[data-color="accent"] {
    background-color: var(--accent-color);
  }
}

//INPUT, SELECT, TEXTAREA
input.outlined,
select.outline,
textarea.outline {
  display: block;
  width: 100%;
  outline: none;
  border: 2px solid var(--border-color);
  border-radius: 8px;
  padding: 0.62rem 0.85rem;
  font-weight: 300;
  background: var(--input-background-color);
  color: currentColor;
  &:hover,
  &:focus,
  &:focus-within {
    border: 2px solid var(--primary-color);
  }
  & + span.error_message {
    visibility: hidden;
  }
  &.ng-valid.ng-touched.ng-dirty[data-validate] {
    border: 2px solid var(--accent-color);
  }
  &.ng-invalid.ng-touched.ng-dirty[data-validate] {
    color: var(--warn-color);
    border: 2px solid var(--warn-color);
  }
  &.ng-invalid.ng-touched.ng-dirty[data-validate] + span.error_message {
    visibility: visible;
    color: var(--warn-color);
  }

  &.ng-invalid.ng-touched.ng-dirty[data-error] {
    color: var(--warn-color);
    border: 2px solid var(--warn-color);
  }
  &.ng-invalid.ng-touched.ng-dirty[data-error] + span.error_message {
    visibility: visible;
    color: var(--warn-color);
  }
}

//CHECKBOX
label.checkbox {
  --size: 1.48em;
  --border-radius: 4px;
  display: grid;
  grid-template-columns: min-content max-content;
  align-items: center;
  gap: var(--check-gap, 1.2ch);
  & > * {
    grid-row: 1;
  }
  input.outline[type="checkbox"] {
    grid-column: 1;
    place-self: center;
    width: calc(var(--size) * 0.8);
    height: calc(var(--size) * 0.8);
  }
  input.outline[type="checkbox"]:checked ~ .check-mark {
    border: 2px solid var(--primary-color);
    &::after {
      content: "✓";
      font-size: 0.9em;
      line-height: 0.9em;
      font-weight: bold;
      color: var(--primary-color);
    }
  }
  input.outline[type="checkbox"]:hover:not(:checked) ~ .check-mark {
    border: 2px solid var(--primary-color);
  }
  .check-mark {
    grid-column: 1;
    place-self: center;
    border: none;
    margin: 0;
    width: var(--size);
    height: var(--size);
    border-radius: var(--border-radius);
    border: 2px solid var(--border-color);
    display: grid;
    place-content: center;
    background-color: var(--input-background-color);
  }
}

//RADIO
label.radio {
  --size: 1.48em;
  --border-radius: 4px;
  display: grid;
  grid-template-columns: min-content max-content max-content;
  gap: var(--check-gap, 1.12ch);
  & > * {
    grid-row: 1;
  }
  input[type="radio"] {
    grid-column: 1;
    place-self: center;
    width: calc(var(--size) * 0.8);
    height: calc(var(--size) * 0.8);
  }
  input[type="radio"]:checked ~ .check-mark {
    border: 2px solid var(--primary-color);
    &::after {
      content: "✓";
      font-size: 0.9em;
      line-height: 0.9em;
      font-weight: bold;
      color: var(--primary-color);
    }
  }
  input[type="radio"]:hover:not(:checked) ~ .check-mark {
    border: 2px solid var(--primary-color);
  }
  .check-mark {
    grid-column: 1;
    place-self: center;
    border: none;
    margin: 0;
    width: var(--size);
    height: var(--size);
    border: 2px solid var(--border-color);
    border-radius: 50%;
    display: grid;
    place-items: center;
    background-color: var(--input-background-color);
  }
  span.error_message {
    visibility: hidden;
  }
  .ng-invalid.ng-touched.ng-dirty[data-validate] ~ .check-mark {
    color: var(--warn-color);
    border: 2px solid var(--warn-color);
  }
  .ng-invalid.ng-touched.ng-dirty[data-validate] ~ span.error_message {
    visibility: visible;
    color: var(--warn-color);
  }
}
