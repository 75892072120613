////////////////////////////////////////////////////////////////////////////////////////////////////////
//= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
// Utilities styles
//= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
////////////////////////////////////////////////////////////////////////////////////////////////////////

//SCROLL-CONTROL
.scrollHorizontal-control,
.scrollable {
  overflow-x: auto;
  overflow-y: hidden;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
  z-index: 2;
}
.scrollHorizontal {
  position: relative;
  z-index: 1;
  .scrollHorizontal-position-left,
  .scrollHorizontal-position-right {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 99;
  }
  .scrollHorizontal-position-left {
    left: 0.25rem;
  }
  .scrollHorizontal-position-right {
    right: 0.25rem;
  }

  [data-scroll-right="block"] ~ .scrollHorizontal-position-right {
    visibility: visible;
  }
  [data-scroll-right="none"] ~ .scrollHorizontal-position-right {
    visibility: hidden;
  }

  [data-scroll-left="block"] ~ .scrollHorizontal-position-left {
    visibility: visible;
  }
  [data-scroll-left="none"] ~ .scrollHorizontal-position-left {
    visibility: hidden;
  }
}

//TEXT-TRANSFORM
.underlined-text {
  position: relative;
  display: inline-block;
  width: fit-content;
  &::after {
    position: absolute;
    bottom: -0.154em;
    left: 0;
    width: calc(100% + 1ch);
    height: 2px;
    background-color: var(--primary-color);
    content: " ";
  }
}
.capitalize {
  text-transform: capitalize;
}
.uppercase {
  text-transform: uppercase;
}
.pointer {
  cursor: pointer;
}