@use "src/assets/styles/responsive.mixin.scss" as *;
@use "src/assets/styles/heading.scss" as *;
@use "src/assets/styles/colors.scss" as *;
@use "src/assets/styles/components.scss" as *;
@use "src/assets/styles/utilities.scss" as *;
@import url("https://fonts.googleapis.com/css2?family=Fira+Sans:wght@300;400;700&display=swap");
////////////////////////////////////////////////////////////////////////////////////////////////////////
//= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
// Initial styles
//= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
////////////////////////////////////////////////////////////////////////////////////////////////////////
html {
  font-size: 20px;
  box-sizing: border-box;
  scroll-behavior: smooth;
  *,
  *::before,
  *::after {
    margin: 0px;
    padding: 0px;
    box-sizing: inherit;
  }
  @include lg {
    font-size: 16px;
  }
  @include xs {
    font-size: 15px;
  }
  @media (max-width:#{350px}) {
    font-size: 14px;
  }
  @media (max-width:#{340px}) {
    font-size: 12px;
  }
}
body {
  --gutter: 2.8rem;
  font-family: "Fira Sans", "sans-serif";
  margin: 0;
  padding: 0;
  max-width: 100%;
  min-height: 100vh;
  background-color: var(--background-color);
  @include md {
    --gutter: 1.8rem;
  }
  @include xs {
    --gutter: 1.5rem;
  }
  a {
    text-decoration: none;
    color: var(--primary-color);
  }
  h1,
  h2,
  h3,
  h4,
  p {
    margin-bottom: 0.9em;
    margin-block-end: 0.9em;
  }
  svg {
    width: 1em !important;
    height: 1em !important;
  }
  main {
    min-height: 100vh;
    width: 100%;
    text-align: center;
    margin: 0 auto;
    max-width: 1920px;
  }
}
