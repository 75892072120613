////////////////////////////////////////////////////////////////////////////////////////////////////////
//= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
// Color styles
//= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
////////////////////////////////////////////////////////////////////////////////////////////////////////
body {
  --text-primary-color: #2b1f20cc;
  --background-color: #ffffff;
  --border-color: #cfcdcd;
  --input-background-color: #f2f5f7;
  --transparent-color: #c6c6c6;
}

.primary {
  color: var(--primary-color);
}
body {
  --primary-color: #bf3242;
  --primary-lighter-color: #bf32427a;
  --primary-200-color: #bf324228;
  --primary-darker-color: #992a37;
}

.accent {
  color: var(--accent-color);
}
body {
  --accent-color: #92db5d;
  --accent-lighter-color: #92db5d7a;
  --accent-200-color: #92db5d28;
  --accent-darker-color: #70a125;
}

.warn {
  color: var(--warn-color);
}
body {
  --warn-color: #ff0000;
  --warn-lighter-color: #ff00007a;
  --warn-200-color: #ff000028;
  --warn-darker-color: #bb0202;
}
