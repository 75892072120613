@mixin lg {
  @media (max-width:#{1919px}) {
    @content;
  }
}

@mixin md {
  @media (max-width:#{1279px}) {
    @content;
  }
}

@mixin sm {
  @media (max-width:#{959px}) {
    @content;
  }
}

@mixin xs {
  @media (max-width:#{599px}) {
    @content;
  }
}
